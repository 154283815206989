import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часто задаваемые вопросы в Victoria Sea Club
			</title>
			<meta name={"description"} content={"Парусный спорт - ответы на ваши вопросы, ваше путешествие ждет"} />
			<meta property={"og:title"} content={"FAQ | Часто задаваемые вопросы в Victoria Sea Club"} />
			<meta property={"og:description"} content={"Парусный спорт - ответы на ваши вопросы, ваше путешествие ждет"} />
			<meta property={"og:image"} content={"https://voxeton.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://voxeton.com/img/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://voxeton.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://voxeton.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://voxeton.com/img/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://voxeton.com/img/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://voxeton.com/img/images.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-8">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Часто задаваемые вопросы
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
			Готовитесь к путешествию с компанией Victoria Sea Club? Мы проанализировали некоторые распространенные вопросы, чтобы помочь вам сгладить впечатление от морского отдыха и приключений.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Какие типы яхт доступны для аренды?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Наш флот включает в себя как уютные круизеры для уединенных прогулок, так и более просторные суда, идеально подходящие для групповых и семейных встреч; каждая из них подобрана с учетом комфорта и простоты навигации.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Нужен ли мне опыт плавания, чтобы арендовать яхту?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Нет, наши яхты рассчитаны как на опытных моряков, так и на новичков в морских приключениях, а помощь в навигации предоставляется по желанию.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Что я должен взять с собой на аренду яхты?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Мы рекомендуем взять с собой солнцезащитные средства, удобную одежду, подходящую для моря, и любые личные вещи, которые вы хотели бы иметь на борту. Все остальное необходимое предоставляется на яхте.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Оборудованы ли яхты удобствами для ночевки?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Да, наши яхты для отдыха в выходные дни оснащены всеми необходимыми удобствами для комфортного ночлега, позволяя вам погрузиться в мир грез под мягкое покачивание волн.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Как погода может повлиять на мою аренду?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Безопасность - наш приоритет. Мы внимательно следим за погодными условиями и сообщаем вам о переносе или корректировке планов, если погода представляет какую-либо опасность.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
					Какие меры вы принимаете для обеспечения экологичности?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
					Мы придерживаемся экологических принципов, включая использование экологичных удобств и обеспечение минимального воздействия наших яхт на морскую среду.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});